import React, { useState, useEffect } from 'react';
import { Typography, TextField, Select, MenuItem, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel, IconButton } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglist from '../components/Taglist';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Uploadfp3 from '../components/Uploadfp3';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import Fab from '@mui/material/Fab';
import sortArray from 'sort-array';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { yellow, red, green } from '@mui/material/colors';
import {v4 as uuidv4} from 'uuid';
import "../components/formatlinks.css";

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $postimage: [ImageInput], $file: [FileInput], $resourcelink: [ResourceLinkInput], $tags: [String], $posttype: [String], $blog: String, $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, postimage: $postimage, file: $file, resourcelink: $resourcelink, tags: $tags, posttype: $posttype, blog: $blog, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     resourcelink {
      resourcelinkname
      resourcelinkurl
     }
     accessgroups
     blog
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

const blog2 = gql`
  query blogs2 {
   getblogs {
    blogtitle
    blogdescription
  }
}
`;

const blognamesquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

function Addpost() {
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  const blognamesq = useQuery(blognamesquery, { variables: { idnumber } });
  const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery }],});
  //const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ title, setTitle] = useState();
  const [ mainimage, setMainImage] = useState([]);
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  const [ seltags, setSelTags ] = useState(null);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState();
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const activeStyle = { color: 'green' };
  const [ gotolink, setGoToLink] = useState("/addpost");
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("66de072ffcc8fe62b1a9253b");
  const [ rlinks, setRLinks] = useState([]);
  const [ readytosave, setReadyToSave ] = useState(false);
  const [ dataentered, setDataEntered ] = useState({title: false, posttext: false, tags: false, accessgroups: false});
  const [ astart, setAstart ] = useState();
  const [ processfiles, setProcessFiles ] = useState();
  const [isdirty, setIsDirty] = useState(false);

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleBlogSelect = (e, value) => {
    console.log(e.target.value);
    setBlogsel(e.target.value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
    if (e.target.value.length>0) setDataEntered({ ...dataentered, title: true});
    else setDataEntered({ ...dataentered, title: false});
    setIsDirty(true);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    console.log("data=", data);
    console.log("data.length=", data.length);
    setPostText(data);
    if (data && data.length>8) setDataEntered({ ...dataentered, posttext: true});
    else setDataEntered({ ...dataentered, posttext: false});
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const getaddstart = (data) => {
    setAstart(data);
  }

  const getprocessfiles = (data) => {
    setProcessFiles(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, tags: true});
    else setDataEntered({ ...dataentered, tags: false});
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getmainimage = (data) => {
    setMainImage(data);
  }  

  const getsharearray = (data) => {
    setShareArray(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, accessgroups: true});
    else setDataEntered({ ...dataentered, accessgroups: false});
  }

  const addlink = (event, value) => {
    setRLinks([...rlinks, { resourcelinkid: uuidv4(), resourcelinkname: '',resourcelinkurl:'' }]);
  };

  const deleteresource = (resourcelinkid) => {
    setRLinks(rlinks.filter((rl) => rl.resourcelinkid!==resourcelinkid));
  };  

  const handleUpdateItem = (resourcelinkid, updatedItem) => {
    // Update the item in the array
    setRLinks(prevItems => prevItems.map(item =>
      item.resourcelinkid === resourcelinkid ? updatedItem : item
    ));
  };  

  // save all of the information for the post
  const savepost = () => {
    let author = userinfo.name;
    let authoremail = userinfo.email;
    let file = filenames;
    let resourcelink = rlinks;
    let posttype = ["blog"];
    let blog = blogsel;
    let accessgroups = sharearray;
    let tags = seltags;
    let postimage = mainimage ;

    console.log("filenames=", filenames);
    console.log("tags=", tags);
    console.log("blog=", blog);
    console.log("postimage=", postimage);

    addpost({  variables: { title, posttext, author, authoremail, postimage, file, resourcelink, tags, posttype, blog, accessgroups, position } });

    navigate("/getposts", { replace: true})
  }

  //console.log("data3=", data3);
  useEffect(() => {
    if (blognamesq.data) {
      let blognamespre = blognamesq.data.getuserblogs;
      setBlognames(sortArray(blognamespre.map((blognamespre) => blognamespre.blogtitle)));
   }
  }, [blognamesq.data]);

  useEffect(() => {
    console.log("filenames=", filenames);
  }, [filenames]);

  useEffect(() => {
    console.log("astart=", astart);
    //setReadyToSave(false);
  }, [astart]);

  useEffect(() => {
    console.log("processfiles=", processfiles);
    //setReadyToSave(true);
  }, [processfiles]);

  useEffect(() => {
    console.log("blognames=", blognames);
    if (blognames.length>0) {
      setShowblognames(true);
    } 
  }, [blognames]);

  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  useEffect(() => {
    console.log("dataentered=", dataentered);
    if (dataentered.title && dataentered.posttext && dataentered.tags && dataentered.accessgroups) setReadyToSave(true);
    else setReadyToSave(false);
  }, [dataentered]);

  useEffect(() => {
    console.log("isdirty=", isdirty)
    const handleBeforeUnload = (e) => {
      if (isdirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isdirty]);  

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getposts">View Posts</ToggleButton>
          <ToggleButton value="/addpost">Add Post</ToggleButton>
          <ToggleButton value="/topicalblogs">Blogs</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4">Add Post</Typography>
      </Stack>

    <Grid container>
      <Grid item style={{ margin: "10px" }}>
          <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your post." variant="standard" size="large" sx={{ m: 1, width: 730 }}></TextField><br/>
          <Texteditor func={getposttext} height="700px" width="700px" sx={{ m: 2}}/><br/>
      </Grid>
      <Grid item sx={{ m: 5 }}>
          <Taglist functags={gettags} funcnewtag={getnewtag} width={"375px"} /><br/><br/>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>

            <Box sx={{ m: 1, fontWeight: 500 }}>{"Position: " + position}</Box>
            <Slider
              aria-label="Temperature"
              defaultValue={3}
              value={position}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              onChange={handleChangePosition}
              sx={{width: 200}}
            />
            <Box sx={{ fontSize: 11, ml: 6, display:'flex', alignItems:'center' }}>(Posts with lower numbers appear first.)</Box>


          </Stack><br/>

          <Sharewithwhom funcsharearray={getsharearray}/>

          <Box sx={{ ml: 1, mt: 1, fontWeight: 500 }}>Upload Image</Box>
          <Uploadfp func={getmainimage}/>

          <Box sx={{ ml: 1, mt: 3, fontWeight: 500 }}>Add Files</Box>
          <Uploadfp3 func={getfilenames} funcaddstart={getaddstart} funcprocessfiles={getprocessfiles} />

          <Box sx={{ ml: 1, mt: 3, fontWeight: 500 }}>Add Links</Box>
          <Box sx={{bgcolor: '#f2f0f0', mb: 2}}>
          { rlinks.map((rl, index) => (
              <>
                 <TextField name={"linkname" + index} value={rl.resourcelinkname} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkname: e.target.value })} placeholder="Enter name" variant="standard" sx={{ m: 1, width: 225, fontSize: 10 }}></TextField>
                 <TextField name={"linkurl" + index} value={rl.resourcelinkurl} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkurl: e.target.value })} placeholder="Enter link" variant="standard" sx={{ m: 1, width: 275, fontSize: 10 }}></TextField>
                 <IconButton name={"deleteresource" + index} sx={{ color: red[500], width: 20, height: 20, ml: 1, mt: 2, mr: 1 }} onClick={() => deleteresource(rl.resourcelinkid) }><DeleteIcon /></IconButton><br/>
              </>
            ))
          }

          <IconButton sx={{ color: green[500], width: 20, height: 20, ml: 1, mt: 1, mb: 2 }} onClick={addlink}><AddCircleIcon /></IconButton>
            <br/>
          </Box>

          { showblognames && (

          <>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', mt: 3 }}>
          <Box sx={{ ml: 1, fontWeight: 500 }}>Blog: </Box>
          <Select value={blogsel} sx={{ width: 375}} defaultValue="66de072ffcc8fe62b1a9253b" onChange={handleBlogSelect} variant="standard">
            <MenuItem key="blognamesnone" value="66de072ffcc8fe62b1a9253b" >None</MenuItem>
            {blognames.map((option, index) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
          </Stack>
          </>

          )}          

          <Button onClick={savepost} variant='contained' sx={{mt: 3}} disabled={!readytosave} >Save Post</Button>
          {!readytosave && (
           <Typography sx={{ fontSize: 11, width:350 }} >You must enter a title, some text, tags, and select with whom to share your post, before saving it.</Typography>
          )}

      </Grid>

      <Grid item>

      </Grid>

    </Grid>

    </div>
  );
}

export default Addpost;
